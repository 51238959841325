.rc-tooltip-inner {
  padding: 7px 14px;
  min-height: unset;
  border-radius: 8px;
  background: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  color: var(--default-opaque-neutrals-white, #FFF);
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  box-shadow: 0px 1px 10px 0px rgba(29, 29, 32, 0.08), 0px 4px 5px 0px rgba(29, 29, 32, 0.05), 0px 2px 4px -1px rgba(29, 29, 32, 0.16);
  border: none;
}

.rc-tooltip-content {
  border-radius: 8px;
}

.rc-tooltip-arrow {
  margin-left: 0!important;
  border-top-color: #4A4A4A!important;
}

.rc-tooltip {
  background-color: transparent;
  opacity: 1;
}