#nanochess {
  text-align: center;

  table {
    display: inline-block;
    font-size: 44px;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    font-weight: 100;

    thead {
      tr {
        background: var(--ui2);
        border-radius: 4px 4px 0 0;
      }
    }

    tr {
      background: var(--ui1);

      &:nth-child(even) {
        background: var(--ui2)
      }

    }

    tbody {
      tr:last-child {
        border-radius: 0 0 4px 4px;
      }
    }

    td,
    th {
      text-align: center;
      border-style: solid;
      border-width: 2px;
      height: var(--s4);
      width: var(--s4);
    }
  }

  select {
    width: 100%;
    text-align: center;
  }

  #selectbox {
    display: none;
  }
}
@media screen and (max-width: 516px) {
  #nanochess table {
    font-size: 32px;
  }
}

@media screen and (max-width: 350px) {
  #nanochess table {
    font-size: 30px;
  }
}