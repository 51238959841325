.carousel__slide--visible {
  width: 354px;
  height: 439px;
  box-sizing: border-box;
  border-radius: var(--specs-spacing-spacing-big-default, 12px);
  background: var(--visual-visual-05, #f8f5ff);

  img {
    margin: auto;
    width: auto;
    max-width: 314px;
    max-height: 301px;
  }
}

.carousel {
  width: 448px;
  height: 486px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  align-items: center;
}

.carousel__inner-slide {
  display: flex;
}

.carousel__slider.carousel__slider--horizontal {
  width: 354px;
  height: 439px;
}

.carousel__back-button,
.carousel__next-button {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;

  svg {
    width: 24px;
    height: 24px;
  }
}

.carousel__slide.carousel__slide--hidden {
  border-radius: 12px;
  overflow: hidden;
  width: 354px;
  height: 439px;

  img {
    margin: auto;
    width: auto;
    max-width: 314px;
    max-height: 301px;
  }
}

.carousel__dot-group {
  display: flex;
  gap: 16px;
}

.carousel__dot {
  border: none;
  background-color: transparent;
  padding: 0;

  span {
    margin: 0;
    display: block;
    background-color: #d9d9d9;
    border-radius: 100%;
    height: 8px;
    width: 8px;
  }
}

.carousel__dot--selected {
  span {
    background-color: #4a4a4a;
  }
}

@media screen and (max-width: 540px) {
  .carousel {
    width: 90%;

    .carousel__next-button,
    .carousel__back-button {
      display: none;
    }
  }

  .carousel__slider.carousel__slider--horizontal {
    width: 100% !important;
  }

  .carousel__slide--visible img {
    max-width: 90%;
  }
}
