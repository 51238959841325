.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}

.single-thumb .range-slider__range {
  height: 3px!important;
  background: var(--default-opaque-primary-primary-50, #D3321F);
}

.range-slider__thumb[data-upper] {
  background: white;
  border-radius: 100%;
  border: 2px solid #D3321F;
  height: 10px;
  width: 10px;
}

.single-thumb {
  width: 200px;
  height: 3px;
  background: var(--default-opaque-neutrals-gray-15, #D9D9D9);
}