.accordion {
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid #D9D9D9;
}

.accordion__button {
  background-color: transparent;
  color: #444;
  cursor: pointer;
  width: 100%;
  height: 62px;
  border: none;
  display: flex;
  align-items: center;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  box-sizing: border-box;
}

.accordion__button:hover {
  background-color: inherit;
  text-decoration: underline;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  background-image: url('data:image/webp;base64,UklGRuIAAABXRUJQVlA4WAoAAAAQAAAAFwAAFwAAQUxQSFQAAAABJyAQIKw6d2IjIoJLsIok2ck+wMDDQRIQBST/pjbw9QRE9F9h27ZNRtl9CBrKa1DcH4B7fBXrNABbnQY9rb29e6me+k9+1XEdMzADmciE/jCgqQRWUDggaAAAANADAJ0BKhgAGAA+bTSWR6QjIiEoCqiADYloALsME2CaEHPgupVrAAD+4vb/qkhoSa4BzDkiH25htjToip9+aIulqfZlWbPNmZItttCcbrDzKdSemftyv/+2rL06hiDYTaF/0K7CQAAA');
  height: 24px;
  width: 24px;
  margin-right: 8px;
  transition: transform .2s ease-in-out
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotateX(180deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 16px 0 34px 32px;;
  animation: fadein 0.35s ease-in;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@media screen and (max-width: 494px) {
  .accordion__button {
    height: auto;
    min-height: 62px;
    padding: 16px 0;
  }
}