.slider-wrapper.axis-horizontal {
  margin: 0 56px;
}

.carousel .slider-wrapper {
  width: calc(100% - 112px) !important;
}

.carousel-root {
  width: 90%;
  margin-top: 48px;
  max-width: 927px;
}

.carousel.carousel-slider {
  padding-bottom: 40px;
}

.carousel ul.control-dots {
  display: flex;
  gap: 16px;
  margin: 0;
  justify-content: center;
}

.carousel .control-dots li.dot {
  margin: 0;
  display: block;
  background-color: #d9d9d9;
  border-radius: 100%;
  height: 8px;
  width: 8px;
  box-shadow: none !important;
  opacity: 1 !important;
}

li.dot.selected {
  background-color: #4a4a4a !important;
}

.control-arrow {
  width: 24px;
  height: 24px;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  opacity: 1 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;

  &::before {
    width: 24px;
    height: 24px;
    margin: 0 !important;
    border: none !important;
    opacity: 1 !important;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.control-arrow.control-prev {
  &::before {
    background-image: url('data:image/svg+xml,<svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path id=" icon" fill-rule="evenodd" clip-rule="evenodd" d="M9.48727 18.5L0.5 9.5L9.5 0.5L10.3911 1.39109L2.28218 9.5L10.3784 17.5962L9.48727 18.5Z" fill="%23737373"/></svg>');
  }
}

.control-arrow.control-next {
  &::before {
    background-image: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="information-direction/next-carousel"><path id=" icon" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 20.5962L15.5962 12.5L7.5 4.39109L8.39109 3.5L17.3784 12.5L8.39109 21.5L7.5 20.5962Z" fill="%23737373"/></g></svg>');
  }
}

@media screen and (max-width: 500px) {
  .control-arrow {
    display: none !important;
  }

  .slider-wrapper.axis-horizontal {
    margin: 0 !important;
  }

  .carousel .slider-wrapper {
    width: 100% !important;
  }
}
