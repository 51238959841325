@use "sass:meta";

.gb-font-maker-scope {
  @include meta.load-css("pure-react-carousel/dist/react-carousel.es.css");
  @include meta.load-css("react-responsive-carousel/lib/styles/carousel.css");
  @include meta.load-css("react-dropdown/style.css");
  @include meta.load-css("react-range-slider-input/dist/style.css");
  @include meta.load-css("rc-tooltip/assets/bootstrap_white.css");
  @include meta.load-css("./components/Carousel/styles.scss");
  @include meta.load-css("./components/FAQ/styles.scss");
  @include meta.load-css("./components/ProgressBar/styles.scss");
  @include meta.load-css("./components/Tooltip/styles.scss");
  @include meta.load-css("./pages/CopyAndPaste/ChessSymbols/styles.scss");
  @include meta.load-css("./pages/FontGenerator/Fancy/styles.scss");

  font-family: "Museo Sans", "GB Museo Sans", Arial, Helvetica, sans-serif;
  margin: 0 0 60px;

  * {
    box-sizing: border-box;
    color: inherit;
    margin: 0;
  }
}
